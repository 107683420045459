import React, { useState } from "react"

import {
  Collapse,
  Box as MKBox,
  Typography as MKTypography,
  Link as MuiLink,
} from "@mui/material"

import NavbarDropdown from "./NavbarDropdown"

function NavbarMobile({ routes, open }: any) {
  const [collapse, setCollapse] = useState<boolean | string>("")

  const handleSetCollapse = (name: any) =>
    collapse === name ? setCollapse(false) : setCollapse(name)

  const renderNavbarItems = routes.map(
    ({
      name,
      icon,
      collapse: routeCollapses,
      href,
      route,
      collapse: navCollapse,
    }: any) => (
      <NavbarDropdown
        key={name}
        name={name}
        icon={icon}
        collapseStatus={name === collapse}
        onClick={() => handleSetCollapse(name)}
        href={href}
        route={route}
        collapse={Boolean(navCollapse)}
      >
        <MKBox sx={{ maxHeight: "15rem", overflowY: "scroll" }}>
          {routeCollapses &&
            routeCollapses.map((item: any) => (
              <MKBox key={item.name} px={2}>
                {item.collapse ? (
                  <>
                    <MKTypography
                      display="block"
                      textTransform="capitalize"
                      py={1}
                      px={0.5}
                      sx={{ fontSize: "14px" }}
                    >
                      <MuiLink
                        href={item.href}
                        underline="none"
                        color="inherit"
                      >
                        {item.name}
                      </MuiLink>
                    </MKTypography>
                    {item.collapse.map((el: any) => (
                      <MKTypography key={el.name}>{el.name}</MKTypography>
                    ))}
                  </>
                ) : (
                  <MKBox key={item.key} display="block">
                    <MKTypography
                      display="block"
                      sx={{ fontSize: "14px" }}
                      textTransform="capitalize"
                    >
                      <MuiLink
                        href={item.href}
                        underline="none"
                        color="inherit"
                      >
                        {item.name}
                      </MuiLink>
                    </MKTypography>
                    <MKTypography
                      display="block"
                      color="text"
                      fontWeight="regular"
                      sx={{ fontSize: "14px", transition: "all 300ms linear" }}
                    >
                      {item.description}
                    </MKTypography>
                  </MKBox>
                )}
              </MKBox>
            ))}
        </MKBox>
      </NavbarDropdown>
    )
  )

  return (
    <Collapse in={Boolean(open)} timeout="auto" unmountOnExit>
      <MKBox width="calc(100% + 1.625rem)" my={2} ml={-2}>
        {renderNavbarItems}
      </MKBox>
    </Collapse>
  )
}

export default NavbarMobile
