import { createTheme } from "@mui/material/styles"
import { Theme } from "@mui/material/styles/createTheme"

import colors from "./base/colors"
import breakpoints from "./base/breakpoints"
import boxShadows from "./base/boxShadows"
import typography from "./base/typography"
import borders from "./base/borders"
import globals from "./base/globals"

import hexToRgb from "./functions/hexToRgb"
import boxShadow from "./functions/boxShadow"
import linearGradient from "./functions/linearGradient"
import pxToRem from "./functions/pxToRem"
import rgba from "./functions/rgba"

const theme: Theme = createTheme({
  breakpoints: { ...breakpoints },
  palette: { ...colors },
  typography: { ...typography },
})

export default theme
