import React from "react"
import { Collapse, Box, Typography } from "@mui/material"
import Icon from "@mui/material/Icon"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"

import ThemeBox from "../ThemeBox"

function NavbarDropdown({
  name,
  icon,
  children,
  collapseStatus,
  light,
  href,
  route,
  collapse,
  ...rest
}: any) {
  const linkComponent = {
    component: "a",
    href,
    target: "_blank",
    rel: "noreferrer",
  }

  const routeComponent = {
    component: "a",
    to: route,
  }

  return (
    <>
      <ThemeBox
        {...rest}
        mx={1}
        py={1}
        display="flex"
        alignItems="center"
        color="#000000"
        sx={{ cursor: "pointer", userSelect: "none" }}
        {...(route && routeComponent)}
        {...(href && linkComponent)}
      >
        <Typography
          variant="body2"
          lineHeight={1}
          color="#000"
          sx={{ alignSelf: "center", "& *": { verticalAlign: "middle" } }}
        >
          {icon}
        </Typography>
        <Typography
          variant="button"
          fontWeight="regular"
          textTransform="capitalize"
          color="#000000"
          sx={{ fontWeight: "100%", ml: 1, mr: 0.25 }}
        >
          {name}
        </Typography>
        <Typography variant="body2" color="#000000" ml="auto">
          {collapse && <KeyboardArrowDownIcon fontSize="small" />}
        </Typography>
      </ThemeBox>
      {children && (
        <Collapse in={Boolean(collapseStatus)} timeout={400} unmountOnExit>
          {children}
        </Collapse>
      )}
    </>
  )
}

export default NavbarDropdown
