import React, { Fragment, useState, useEffect } from "react"

import {
  Container,
  Box as MKBox,
  Typography as MKTypography,
  Button as MKButton,
  Icon,
  Popper,
  Grow,
  Grid,
  Divider,
  Link as MuiLink,
  IconButton,
  Box,
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import MenuIcon from "@mui/icons-material/Menu"
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp"
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight"

import NavbarDropdown from "./NavbarDropdown"
import NavbarMobile from "./NavbarMobile"
import ThemeBox from "../ThemeBox"
// @ts-ignore
import logo from "../../resources/savana/savanna-logo.png"

// Material Kit 2 React base styles
import breakpoints from "../../theme/base/breakpoints"

function DefaultNavbar({
  brand,
  routes,
  transparent,
  light,
  action,
  sticky,
  relative,
  center,
}: any) {
  const [dropdown, setDropdown] = useState<any>("")
  const [dropdownEl, setDropdownEl] = useState("")
  const [dropdownName, setDropdownName] = useState("")
  const [nestedDropdown, setNestedDropdown] = useState<any>("")
  const [nestedDropdownEl, setNestedDropdownEl] = useState("")
  const [nestedDropdownName, setNestedDropdownName] = useState("")
  const [arrowRef, setArrowRef] = useState(null)
  const [mobileNavbar, setMobileNavbar] = useState(false)
  const [mobileView, setMobileView] = useState(false)

  const openMobileNavbar = () => setMobileNavbar(!mobileNavbar)

  useEffect(() => {
    // A function that sets the display state for the DefaultNavbarMobile.
    function displayMobileNavbar() {
      if (window.innerWidth < breakpoints.values.lg) {
        setMobileView(true)
        setMobileNavbar(false)
      } else {
        setMobileView(false)
        setMobileNavbar(false)
      }
    }

    /** 
     The event listener that's calling the displayMobileNavbar function when 
     resizing the window.
    */
    window.addEventListener("resize", displayMobileNavbar)

    // Call the displayMobileNavbar function to set the state with the initial value.
    displayMobileNavbar()

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", displayMobileNavbar)
  }, [])

  const renderNavbarItems = routes.map(
    ({ name, icon, href, route, collapse }: any) => (
      <NavbarDropdown
        key={name}
        name={name}
        icon={icon}
        href={href}
        route={route}
        collapse={Boolean(collapse)}
        onMouseEnter={({ currentTarget }: any) => {
          if (collapse) {
            setDropdown(currentTarget)
            setDropdownEl(currentTarget)
            setDropdownName(name)
          }
        }}
        onMouseLeave={() => collapse && setDropdown(null)}
        light={light}
      />
    )
  )

  // Render the routes on the dropdown menu
  const renderRoutes = routes.map(
    ({ name, collapse, columns, rowsPerColumn }: any) => {
      let template
      // Render the dropdown menu that should be display as columns
      if (collapse && columns && name === dropdownName) {
        const calculateColumns = collapse.reduce(
          (resultArray: any, item: any, index: any) => {
            const chunkIndex = Math.floor(index / rowsPerColumn)

            if (!resultArray[chunkIndex]) {
              resultArray[chunkIndex] = []
            }

            resultArray[chunkIndex].push(item)

            return resultArray
          },
          []
        )

        template = (
          <Grid key={name} container spacing={3} py={1} px={1.5}>
            {calculateColumns.map((cols: any, key: any) => {
              const gridKey = `grid-${key}`
              const dividerKey = `divider-${key}`

              return (
                <Grid
                  key={gridKey}
                  item
                  xs={12 / columns}
                  sx={{ position: "relative" }}
                >
                  {cols.map((col: any, index: any) => (
                    <Fragment key={col.name}>
                      <MKTypography
                        display="block"
                        variant="button"
                        fontWeight="bold"
                        textTransform="capitalize"
                        py={1}
                        px={0.5}
                        mt={index !== 0 ? 2 : 0}
                      >
                        {col.name}
                      </MKTypography>
                      {col.collapse.map((item: any) => (
                        <MKTypography sx={{ fontSize: "14px" }}>
                          {item.name}
                        </MKTypography>
                      ))}
                    </Fragment>
                  ))}
                  {key !== 0 && (
                    <Divider
                      key={dividerKey}
                      orientation="vertical"
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "-4px",
                        transform: "translateY(-45%)",
                        height: "90%",
                      }}
                    />
                  )}
                </Grid>
              )
            })}
          </Grid>
        )

        // Render the dropdown menu that should be display as list items
      } else if (collapse && name === dropdownName) {
        template = collapse.map((item: any) => {
          return (
            <MKTypography
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                fontWeight: "bold",
              }}
              key={item.name}
              onMouseEnter={({ currentTarget }: any) => {
                if (item.dropdown) {
                  setNestedDropdown(currentTarget)
                  setNestedDropdownEl(currentTarget)
                  setNestedDropdownName(item.name)
                }
              }}
              onMouseLeave={() => {
                if (item.dropdown) {
                  setNestedDropdown(null)
                }
              }}
            >
              {item.description ? (
                <MKBox>
                  {item.name}
                  <MKTypography
                    display="block"
                    variant="button"
                    color="text"
                    fontWeight="regular"
                    sx={{ transition: "all 300ms linear", fontSize: "14px" }}
                  >
                    {item.description}
                  </MKTypography>
                </MKBox>
              ) : (
                <MKTypography sx={{ fontSize: "14px" }}>
                  <MuiLink href={item.href} underline="none" color="inherit">
                    {item.name}
                  </MuiLink>
                </MKTypography>
              )}
              {item.collapse && (
                <KeyboardArrowRightIcon
                  fontSize="small"
                  sx={{ mr: -0.5, verticalAlign: "middle" }}
                />
              )}
            </MKTypography>
          )
        })
      }
      return template
    }
  )

  // Routes dropdown menu
  const dropdownMenu = (
    <Popper
      anchorEl={dropdown}
      popperRef={null}
      open={Boolean(dropdown)}
      placement="top-start"
      transition
      style={{ zIndex: 10 }}
      modifiers={[
        {
          name: "arrow",
          enabled: true,
          options: {
            element: arrowRef,
          },
        },
      ]}
      onMouseEnter={() => setDropdown(dropdownEl)}
      onMouseLeave={() => {
        if (!nestedDropdown) {
          setDropdown(null)
          setDropdownName("")
        }
      }}
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps}>
          <ThemeBox borderRadius="lg" sx={{ backgroundColor: "#DDDDDD", p: 1 }}>
            <ArrowDropUpIcon
              fontSize="large"
              sx={{ mt: "-60px", color: "#DDDDDD" }}
            />
            <ThemeBox sx={{ mt: "-30px" }}>{renderRoutes}</ThemeBox>
          </ThemeBox>
        </Grow>
      )}
    </Popper>
  )

  // Render routes that are nested inside the dropdown menu routes
  const renderNestedRoutes = routes.map(({ collapse, columns }: any) =>
    collapse && !columns
      ? collapse.map(({ name: parentName, collapse: nestedCollapse }: any) => {
          let template

          if (parentName === nestedDropdownName) {
            template =
              nestedCollapse &&
              nestedCollapse.map((item: any) => {
                const linkComponent = {
                  component: MuiLink,
                  href: item.href,
                  target: "_blank",
                  rel: "noreferrer",
                }

                return (
                  <MKTypography
                    key={item.name}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    variant="button"
                    textTransform="capitalize"
                    minWidth={item.description ? "14rem" : "12rem"}
                    color={item.description ? "dark" : "text"}
                    fontWeight={item.description ? "bold" : "regular"}
                    py={item.description ? 1 : 0.625}
                    px={2}
                  >
                    {item.description ? (
                      <MKBox>
                        {item.name}
                        <MKTypography
                          display="block"
                          variant="button"
                          color="text"
                          fontWeight="regular"
                          sx={{ transition: "all 300ms linear" }}
                        >
                          {item.description}
                        </MKTypography>
                      </MKBox>
                    ) : (
                      item.name
                    )}
                    {item.collapse && (
                      <Icon
                        fontSize="small"
                        sx={{
                          fontWeight: "normal",
                          verticalAlign: "middle",
                          mr: -0.5,
                        }}
                      >
                        keyboard_arrow_right
                      </Icon>
                    )}
                  </MKTypography>
                )
              })
          }

          return template
        })
      : null
  )

  // Dropdown menu for the nested dropdowns
  const nestedDropdownMenu = (
    <Popper
      anchorEl={nestedDropdown}
      popperRef={null}
      open={Boolean(nestedDropdown)}
      placement="right-start"
      transition
      style={{ zIndex: 10 }}
      onMouseEnter={() => {
        setNestedDropdown(nestedDropdownEl)
      }}
      onMouseLeave={() => {
        setNestedDropdown(null)
        setNestedDropdownName("")
        setDropdown(null)
      }}
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps}>
          <MKBox ml={2.5} mt={-2.5} borderRadius="lg">
            <MKBox>{renderNestedRoutes}</MKBox>
          </MKBox>
        </Grow>
      )}
    </Popper>
  )

  return (
    <Box sx={{ backgroundColor: "#f1f2f6" }}>
      <Container
        maxWidth="xl"
        sx={sticky ? { position: "sticky", top: 0, zIndex: 10 } : { p: 1 }}
      >
        <ThemeBox>
          <ThemeBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <ThemeBox>
              <a href="/">
                <img src={logo} className="App-logo" alt="Savanna HR Logo" />
              </a>
            </ThemeBox>
            <ThemeBox
              color="inherit"
              display={{ xs: "none", lg: "flex" }}
              ml="auto"
              mr={center ? "auto" : 0}
            >
              {renderNavbarItems}
            </ThemeBox>
            <ThemeBox ml={{ xs: "auto", lg: 0 }}>
              {action &&
                (action.type === "internal" ? (
                  <MKButton color="primary" size="small">
                    {action.label}
                  </MKButton>
                ) : (
                  <MKButton color="primary" size="small">
                    {action.label}
                  </MKButton>
                ))}
            </ThemeBox>
            <ThemeBox
              display={{ xs: "inline-block", lg: "none" }}
              lineHeight={0}
              py={1.5}
              pl={1.5}
              color={transparent ? "white" : "inherit"}
              sx={{ cursor: "pointer" }}
              onClick={openMobileNavbar}
            >
              {mobileNavbar ? (
                <CloseIcon color="primary" />
              ) : (
                <MenuIcon color="primary" />
              )}
            </ThemeBox>
          </ThemeBox>
          <ThemeBox>
            {mobileView && <NavbarMobile routes={routes} open={mobileNavbar} />}
          </ThemeBox>
        </ThemeBox>
        {dropdownMenu}
        {nestedDropdownMenu}
      </Container>
    </Box>
  )
}

export default DefaultNavbar
