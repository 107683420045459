import React, { ReactNode } from "react"
import { ThemeProvider } from "@mui/material/styles"
import CssBaseline from "@mui/material/CssBaseline"
import DashboardIcon from "@mui/icons-material/Dashboard"
import { Helmet } from "react-helmet"

import theme from "../../theme"
import DefaultNavbar from "../Navbars"

interface Props {
  children: ReactNode
  description: string
  title: string
}

const routes = [
  {
    name: "About",
    icon: <DashboardIcon sx={{ fontSize: "14px" }} />,
    collapse: [
      {
        name: "Media Release",
        href: "/",
      },
      {
        name: "Life at Savanna HR",
        href: "/",
      },
      {
        name: "Awards",
        href: "/",
      },
      {
        name: "Reviews",
        href: "/",
      },
    ],
  },
  {
    name: "Job Seeker",
    icon: <DashboardIcon sx={{ fontSize: "14px" }} />,
    collapse: [
      {
        name: "Find Jobs",
        href: "/",
      },
      {
        name: "Jobs by Location",
        href: "/",
      },
      {
        name: "Jobs by Industry",
        href: "/",
      },
      {
        name: "Jobs by Education",
        href: "/",
      },
      {
        name: "Jobs by Location",
        href: "/",
      },
      {
        name: "Jobs by Function",
        href: "/",
      },
    ],
  },
  {
    name: "Hiring Solutions",
    icon: <DashboardIcon sx={{ fontSize: "14px" }} />,
    collapse: [
      {
        name: "Recruitment Services",
        href: "/hiring-solutions/recruitment-services",
      },
      {
        name: "Technology Hiring",
        href: "/hiring-solutions/technology-hiring",
      },
      {
        name: "Executive Search",
        href: "/hiring-solutions/executive-search",
      },
      {
        name: "Job advertisement",
        href: "/",
      },
      {
        name: "Application Tracking System",
        href: "/",
      },
    ],
  },
  {
    name: "Career Advice/Tips",
    icon: <DashboardIcon sx={{ fontSize: "14px" }} />,
    collapse: [
      {
        name: "Salary & Negotiation",
        href: "/career-advice/salary-negotiation",
      },
      {
        name: "Work Life Balance",
        href: "/",
      },
      {
        name: "Interview Tips",
        href: "/career-advice/interview-tips",
      },
      {
        name: "Resume & Cover Letter",
        href: "/",
      },
    ],
  },
  {
    name: "Blog",
    href: "/blog",
  },
]

const Layout = ({ children, title, description }: Props) => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="robots" content="noindex" />
        <meta name="description" content={description} />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="HR consultancy in Delhi NCR | best consultancy in Delhi NCR-SAVANNA HR"
        />
        <meta
          property="og:description"
          content="Best consultancy in Delhi NCR We are the best HR Consultancy in Delhi NCR because we know what our clients actually need. We hire leaders for the leading organizations."
        />
        <link href="https://fonts.googleapis.com" rel="preconnect" />
        <link
          href="https://fonts.gstatic.com"
          rel="preconnect"
          crossOrigin="anonymous"
        />
        <script
          src="https://ajax.googleapis.com/ajax/libs/webfont/1.6.26/webfont.js"
          type="text/javascript"
        ></script>
        <script src="https://grwapi.net/widget.min.js"></script>
        <script type="text/javascript">
          {`
            WebFont.load({
              google: {
                families: [
                  "Inter:100,200,300,regular,500,600,700,800,900:cyrillic,cyrillic-ext,greek,greek-ext,latin,latin-ext,vietnamese",
                  "Gloock:regular:cyrillic-ext,latin,latin-ext",
                ],
              },
            });
          `}
        </script>
        <title>{title}</title>
        <script type="application/ld+json">
          {`{
            "@context":"https://schema.org",
            "@type":"Organization",
            "name":"Savanna HR",
            "url":"https://savannahr.com",
            "logo":"https://savannahr.com/static/sav-774f192a16166e221249a3d07c18c0bb.png",
            "sameAs": [
              "https://www.facebook.com/savannahrservices",
              "https://www.instagram.com/hrsavanna",
              "https:://www.linkedin.com/company/savanna-hr/",
              "https://twitter.com/Savanna_HR"
            ]
           }`}
        </script>
      </Helmet>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <DefaultNavbar
          routes={routes}
          action={{
            type: "external",
            route: "/",
            label: "Get Started",
            color: "default",
          }}
          transparent
          light
        />
        {children}
      </ThemeProvider>
    </>
  )
}

export default Layout
