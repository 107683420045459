import Box from "@mui/material/Box"
import { styled } from "@mui/material/styles"

import linearGradient from "../../theme/functions/linearGradient"
import boxShadows from "../../theme/base/boxShadows"
import borders from "../../theme/base/borders"

export default styled(Box)(({ theme, ownerState }: any) => {
  const { palette } = theme
  const {
    variant,
    bgColor,
    color,
    opacity,
    borderRadius,
    shadow,
    coloredShadow,
  } = ownerState

  const { grey, background } = palette

  const { borderRadius: radius } = borders
  const { colored } = boxShadows

  const greyColors = {
    "grey-100": grey[100],
    "grey-200": grey[200],
    "grey-300": grey[300],
    "grey-400": grey[400],
    "grey-500": grey[500],
    "grey-600": grey[600],
    "grey-700": grey[700],
    "grey-800": grey[800],
    "grey-900": grey[900],
  }

  const validGradients = [
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
  ]

  const validColors = [
    "transparent",
    "white",
    "black",
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
    "text",
    "grey-100",
    "grey-200",
    "grey-300",
    "grey-400",
    "grey-500",
    "grey-600",
    "grey-700",
    "grey-800",
    "grey-900",
  ]

  const validBorderRadius = ["xs", "sm", "md", "lg", "xl", "xxl", "section"]
  const validBoxShadows = ["xs", "sm", "md", "lg", "xl", "xxl", "inset"]

  // background value
  let backgroundValue = bgColor

  // color value
  let colorValue = color

  // borderRadius value
  let borderRadiusValue = borderRadius

  // boxShadow value
  let boxShadowValue = "none"

  return {
    opacity,
    background: backgroundValue,
    color: colorValue,
    borderRadius: borderRadiusValue,
    boxShadow: boxShadowValue,
  }
})
