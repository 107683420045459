import React, { forwardRef } from "react"

// prop-types is a library for typechecking of props
import PropTypes from "prop-types"

// Custom styles for MKBox
import MKBoxRoot from "./ThemeBoxRoot"

const ThemeBox = forwardRef(
  (
    {
      variant,
      bgColor,
      color,
      opacity,
      borderRadius,
      shadow,
      coloredShadow,
      ...rest
    }: any,
    ref
  ) => (
    <MKBoxRoot
      {...rest}
      ref={ref}
      ownerState={{
        variant,
        bgColor,
        color,
        opacity,
        borderRadius,
        shadow,
        coloredShadow,
      }}
    />
  )
)

export default ThemeBox
