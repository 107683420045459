import React from "react"

import Layout from "./src/components/layout"

export default ({ element, props }) => {
  const additionalProps = {
    title: "A Human Resource Sourcing Company | Savanna HR",
    description:
      "Best consultancy in Delhi NCR We are the best HR Consultancy in Delhi NCR because we know what our clients actually need. We hire leaders for the leading organizations",
  }
  return (
    <Layout {...props} {...additionalProps}>
      {element}
    </Layout>
  )
}
